// LanguageMenuModalView.js
import { connect } from 'react-redux';

import LanguageMenuModalView from '../component/LanguageMenuModalView.jsx';

import getOperationData from '../selector/getOperationData.js';

const mapStateToProps = state => {
  return {
    modalViewRootId: getOperationData(
      state,
      ['modal', 'LanguageMenu'],
      'modalViewRootId'
    ),
    deviceWidth: getOperationData(state, ['device'], 'width'),
  };
};

export default connect(mapStateToProps)(LanguageMenuModalView);
