// LanguageMenuModalView.jsx
import React from 'react';
import PropTypes from 'prop-types';

import ModalView from '../container/ModalView.js';

import UniversalContainer from '../component/UniversalContainer.jsx';
import { ModalId } from '../resource/mixpanel.js';

import { breakpoint } from '../style/variables.js';

const MODAL_VIEW_STYLE = {
  position: 'static',
};

export const LanguageMenuModalView = ({
  modalViewRootId = null,
  deviceWidth = 0,
}) => {
  const isMobile = deviceWidth <= breakpoint.mobile;
  const shouldUseDropdownLayout = !isMobile && modalViewRootId;
  return (
    <ModalView
      modalId={ModalId.Settings.ModalLanguageMenu}
      id="LanguageMenu"
      rootId={shouldUseDropdownLayout ? `#${modalViewRootId}` : undefined}
      modalViewStyle={shouldUseDropdownLayout ? MODAL_VIEW_STYLE : undefined}
      render={() => (
        <UniversalContainer
          filename="LanguageMenu"
          useSuspense={false}
          shouldUseDropdownLayout={shouldUseDropdownLayout}
        />
      )}
    />
  );
};

LanguageMenuModalView.propTypes = {
  modalViewRootId: PropTypes.string,
  deviceWidth: PropTypes.number,
};

export default LanguageMenuModalView;
